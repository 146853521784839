import { IMAGE_PLACEHOLDER } from '../store/constants'

function renderPlaceholder(el, styles) {
  if (typeof styles === 'object' && styles !== null) {
    for (const style in styles) {
      el.style[style] = styles[style]
    }
  }
  el.src = IMAGE_PLACEHOLDER
}

export default {
  name: 'product-placeholder',
  object: {
    bind: function (el, binding) {
      const styles = binding.value

      if (!el.src) {
        renderPlaceholder(el, styles)
      }

      window.addEventListener('load', () => {
        if (!el.src) {
          renderPlaceholder(el, styles)
        }
      })

      el.onerror = (event) => {
        el.onerror = null
        renderPlaceholder(el, styles)
      }
    }
  }
}
